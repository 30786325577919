
#detail-info {
	position: absolute;
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
	transition: 0.4s ease-in-out;
}

#detail-info.show {
	visibility: visible;
	opacity: 1;
}

#detail-info.desktop {	
	top: 24px;
	left: 20px;
}

#detail-info.desktop.show {	
	left: 30px;
}

#detail-info.mobile {
	display: flex;
    flex: 1;
    justify-content: center;
	width: 100%;
	bottom: 75px;
}

#detail-info.mobile.show {
	bottom: 80px;
}

#detail-img.mobile {
	width: 100%;
	max-width: 424px;
	margin: 0 20px;
}