
#logo {
	position: absolute;
	pointer-events: none;
}

#logo.desktop {
	right: 22px;
	bottom: 43px;
}

#logo.mobile {
	top: 24px;
	left: 24px;
}